import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Modal } from 'react-bootstrap'
import './index.scss';
import "antd/dist/antd.min.css";
import { useGetAllUsersQuery, useCreateUserMutation, useUpdateUserMutation, useDeleteProductMutation } from '../../services/google_merchant';
import { useSelector } from "react-redux";
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import TableSkeleton from '../TableSkeleton';
import Loader from '../../Helper/Loader';
import LinearProgress from '@mui/material/LinearProgress';
import LanguageIcon from '@mui/icons-material/Language';
function GoogleMerchant() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [getSearchQuery, searchQuery] = useState("")
  const page = new URLSearchParams(location.search).get('page') || null
  const limit = new URLSearchParams(location.search).get('size') || 15
  const { merchant_id } = useParams();
  const productInfo = useGetAllUsersQuery({
    page: page,
    size: limit,
    merchantId: merchant_id,
    userId: userId,
    userToken: userToken,
    admin: admin,
    searchquery: getSearchQuery,
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const products = productInfo?.data?.products;
  const productStatus = productInfo?.data?.productStatus;
  const pagination = productInfo?.data?.pagination;

  const [createNew, createresult] = useCreateUserMutation()
  const [deleteList, deleteresult] = useDeleteProductMutation()

  const [show, setShow] = useState(false);
  const [getEditShow, setEditShow] = useState(false);
  const [getTitle, setTitle] = useState("")
  const [getOfferId, setOfferId] = useState("")
  const [getCountry, setCountry] = useState("")
  const [getCondition, setCondition] = useState("")
  const [getPrice, setPrice] = useState(0)
  const [getImageUrls, setImageUrls] = useState("")
  const [getProductUrl, setProductUrl] = useState("")
  const [getCurrency, setCurrency] = useState("")
  const [getDescription, setDescription] = useState("")
  const [getAvailability, setAvailability] = useState("")
  const [getProductId, setProductId] = useState("")

  function handleCloseEdit() {
    setEditShow(false)
  }
  function handleOpenEdit(data) {
    setEditShow(true)
    setTitle(data.title)
    setOfferId(data.offerId)
    setCurrency(data.price?.currency)
    setDescription(data.description)
    setAvailability(data.availability)
    setProductUrl(data.link)
    setCountry(data.country)
    setCondition(data.condition)
    setPrice(data.price?.value)
    setImageUrls(data.imageLink)
    setProductId(data.id)

  }
  function handleClose() {
    setShow(false)
  }
  function handleOpen() {
    setShow(true)
  }

  const [updatePost, updateresult] = useUpdateUserMutation()

  useEffect(() => {
    if (updateresult?.isSuccess) {
      setEditShow(false)
      setChecked([])
    }
    if (createresult?.isSuccess) {
      setShow(false)
    }
  }, [updateresult, createresult]);

  function createNewPost(data) {
    createNew({
      merchantId: merchant_id,
      offerId: data.offerId,
      title: data.title,
      description: data.description,
      link: data.link,
      imageLink: data.imageLink,
      targetCountry: data.targetCountry,
      availability: data.availability,
      condition: data.condition,
      price: data.price,
      currency: data.currency,
      userId: userId,
      userToken: userToken,
      admin: admin
    })
  }

  const updateData = {
    merchantId: merchant_id,
    offerId: getOfferId,
    productId: getProductId,
    title: getTitle,
    description: getDescription,
    link: getProductUrl,
    imageLink: getImageUrls,
    targetCountry: getCountry,
    availability: getAvailability,
    condition: getCondition,
    price: getPrice,
    currency: getCurrency,
    userId: userId,
    userToken: userToken,
    admin: admin
  }

  useEffect(() => {
    if (createresult?.isSuccess) {
      reset()
    }
  }, [createresult]);


  function getLoadingProgress(thisLoading, thisFetching) {
    if (thisLoading) {
      return true
    } else if (thisFetching) {
      return true
    } else {
      return false
    }
  }


  function fetchProductStatus(productId) {
    const thidata = productStatus?.filter(obj => obj.productId === productId);
    return thidata?.[0]?.productId
  }



  return (
    <div className="tabel_cards">

      <Modal className="new-post-modal" show={show} onHide={handleClose} centered animation={true}>
        <Modal.Body>

          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={handleClose}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>Add New Product</h5>
            </div>
          </div>
          <form onSubmit={handleSubmit(createNewPost)}>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>ID</label>
                      <input type="text" className="form-control" id="offerId"
                        aria-invalid={errors.offerId ? "true" : "false"}
                        {...register('offerId', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Price</label>
                      <input type="text" className="form-control" id="price"
                        aria-invalid={errors.price ? "true" : "false"}
                        {...register('price', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Currency</label>
                      <input type="text" className="form-control" id="currency"
                        aria-invalid={errors.currency ? "true" : "false"}
                        {...register('currency', { required: true })} />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Title</label>
                      <input type="text" className="form-control" id="title"
                        aria-invalid={errors.title ? "true" : "false"}
                        {...register('title', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Description</label>
                      <textarea className="form-control" id="description"
                        aria-invalid={errors.description ? "true" : "false"}
                        {...register('description', { required: true })} ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Product URL</label>
                      <input type="text" className="form-control" id="link"
                        aria-invalid={errors.link ? "true" : "false"}
                        {...register('link', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Image URL</label>
                      <input type="text" className="form-control" id="imageLink"
                        aria-invalid={errors.imageLink ? "true" : "false"}
                        {...register('imageLink', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Country</label>
                      <select className="form-control" id="targetCountry"
                        aria-invalid={errors.targetCountry ? "true" : "false"}
                        {...register('targetCountry', { required: true })}>
                        <option value={'AE'} selected>UAE</option>
                        <option value={'US'}>United State</option>
                        <option value={'UK'}>United Kingdom</option>

                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Availability</label>
                      <select className='form-control' id="availability"
                        aria-invalid={errors.availability ? "true" : "false"}
                        {...register('availability', { required: true })}>
                        <option value={'In Stock'} selected>In Stock</option>
                        <option value={'Out of Stock'}>Out of Stock</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Condition</label>
                      <input type="text" className="form-control" id="condition"
                        aria-invalid={errors.condition ? "true" : "false"}
                        {...register('condition', { required: true })} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                      <Button disabled={createresult?.isLoading ? true : false}
                        color="info"
                        variant="contained"
                        size="small"
                        type="submit">
                        {createresult?.isLoading ? 'Loading...' : 'Create'}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>



      <Modal className="new-post-modal" show={getEditShow} onHide={handleCloseEdit} centered animation={true}>
        <Modal.Body>
          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={handleCloseEdit}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>Update Product</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Merchant</label>
                    <select className="form-control" onChange={(e) => setMerchant(e.target.value)}>
                      <option value={''} selected disabled>Select</option>
                      <option value="5320178588">5320178588</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>ID</label>
                    <input type="text" className="form-control" defaultValue={getOfferId} onChange={(e) => setOfferId(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Price</label>
                    <input type="text" className="form-control" defaultValue={getPrice} onChange={(e) => setPrice(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Currency</label>
                    <input type="text" className="form-control" defaultValue={getCurrency} onChange={(e) => setCurrency(e.target.value)} />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label>Title</label>
                    <input type="text" className="form-control" defaultValue={getTitle} onChange={(e) => setTitle(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Description</label>
                    <textarea className="form-control" defaultValue={getDescription} onChange={(e) => setDescription(e.target.value)} ></textarea>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Product URL</label>
                    <input type="text" className="form-control" defaultValue={getProductUrl} onChange={(e) => setProductUrl(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Image URL</label>
                    <input type="text" className="form-control" defaultValue={getImageUrls} onChange={(e) => setImageUrls(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Country</label>
                    <select className="form-control" defaultValue={getCountry} onChange={(e) => setCountry(e.target.value)}>
                      <option value={'AE'} selected>UAE</option>
                      <option value={'US'}>United State</option>
                      <option value={'UK'}>United Kingdom</option>

                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Availability</label>
                    <select className='form-control' defaultValue={getAvailability} onChange={(e) => setAvailability(e.target.value)}>
                      <option value={'In Stock'} selected>In Stock</option>
                      <option value={'Out of Stock'}>Out of Stock</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Condition</label>
                    <input type="text" className="form-control" defaultValue={getCondition} onChange={(e) => setCondition(e.target.value)} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Button disabled={updateresult?.isLoading ? true : false}
                      color="info"
                      variant="contained"
                      size="small"
                      onClick={() => { updatePost(updateData) }}
                    >
                      {updateresult?.isLoading ? 'Loading...' : 'Update'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="newFlexPagi">
          <div className="col-md-12">
            <div className="storeCardB">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap' }}>
                {/* <div>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div> */}
                <div className="Order-Pagination">
                  <Button
                    disabled={productInfo?.isLoading || !page}
                    variant="outlined"
                    size="small"
                    component={Link}
                    to={{
                      pathname:
                        `${window.location.pathname}`,
                      search: page && ``
                    }}
                  >
                    {productInfo?.isLoading ?
                      <span>Loadind...</span> :
                      <span> First </span>}
                  </Button>

                  <Button
                    disabled={productInfo?.isLoading}
                    style={{ margin: '0px 10px' }}
                    variant="outlined"
                    size="small"
                    component={Link}
                    to={{
                      pathname:
                        `${window.location.pathname}`,
                      search: pagination && `?page=${pagination}`
                    }}
                  >
                    {productInfo?.isLoading ?
                      <span>Loadind...</span> :
                      <span>Next</span>}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="storeCardB">
            <div className="updateButton">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ position: 'relative', marginRight: '10px' }}>
                </div>
              </div>
              <div className="table_search_card">
                <div style={{ margin: '0 10px' }}>
                  <Button
                    disabled={productInfo?.isLoading}
                    // style={{background: '#4e5053'}}
                    variant="outlined"
                    size="small"
                    onClick={() => handleOpen(true)}>
                    Add New
                  </Button>
                </div>
                <div>
                  <Button
                    disabled={productInfo?.isLoading}
                    variant="outlined"
                    size="small"
                    component={Link}
                    to={`/google-merchant/action/${merchant_id}`}
                  >
                    Action
                  </Button>
                </div>
                <div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="tabelOverflow" style={{ position: 'relative' }}>
            {getLoadingProgress(productInfo?.isLoading, productInfo?.isFetching) &&
              <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                <LinearProgress style={{ height: '2px' }} />
              </div>
            }
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: '0px' }}>ID</th>
                  <th style={{ textAlign: 'center' }}>Image</th>
                  <th>Title</th>
                  <th>Price</th>
                  <th>Country</th>
                  <th>Availability</th>
                  <th>Status</th>
                  <th className="sticky-column tar" style={{ textAlign: 'right', width: '1px' }}>Manage</th>
                </tr>
              </thead>
              {/* {productInfo?.isLoading &&
                TableSkeleton(8)
              } */}

              <tbody style={{ position: 'relative' }}>

                {products?.map((item, i) =>
                  <tr>
                    <td>{item.offerId}</td>
                    <td style={{ textAlign: 'center' }}>
                      <img src={`${item.imageLink}`} style={{ width: '35px', height: '35px', objectFit: 'contain' }} />
                    </td>
                    <td>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                          disabled
                          onClick={() => window.open(``, '_blank').focus()}
                        >
                          <LanguageIcon style={{ fontSize: '12px' }} />
                        </IconButton>
                        <IconButton
                          onClick={() => handleOpenEdit(item)}
                        >
                          <EditIcon style={{ fontSize: '12px' }} />
                        </IconButton>
                        <Link to={`/product/${item.offerId}`} style={{ whiteSpace: 'initial' }}>
                          {item.title}
                        </Link>
                      </div>
                    </td>
                    <td>{item.price?.currency} {item.price?.value}</td>
                    <td style={{ whiteSpace: 'initial' }}>{item.targetCountry}</td>
                    <td style={{ whiteSpace: 'initial' }}>{item.availability}</td>
                    <td style={{ whiteSpace: 'initial' }}>
                      {/* {item.condition} */}

                      {fetchProductStatus(item.id)}
                    </td>

                    <td className="sticky-column">
                      <div className="dFlex center nowrap jcr">
                        <Button
                          onClick={() => deleteList({
                            merchantId: merchant_id,
                            id: item.offerId,
                            product_id: item.id,
                            userId: userId,
                            userToken: userToken,
                            admin: admin
                          })}
                          color={`error`}
                          variant={`outlined`}
                          size="small"
                          disabled={deleteresult?.isLoading && deleteresult?.originalArgs?.id === item.offerId ? true : false}
                        >
                          <small className="dFlex">{deleteresult?.isLoading && deleteresult?.originalArgs?.id === item.offerId ? `Deleting...` : `Delete`}</small>
                        </Button>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {products?.length === 0 &&
            <div style={{ padding: '20px', textAlign: 'center' }}>Not Data Found!</div>
          }
        </div>
      </div>
      <br /><br />
      {!productInfo?.isLoading &&
        <>
          {productInfo?.error?.originalStatus === 403 &&
            <div className="row reject-bottom-message" style={{ margin: 0, padding: 0, justifyContent: 'center' }}>
              <div className="col-md-6" style={{ padding: 0 }}>
                <div className='alert alert-warning' style={{ textAlign: 'center' }}>{productInfo?.error?.data}</div>
              </div>
            </div>
          }
        </>
      }
    </div>
  );
}

export default GoogleMerchant;
