import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Spinner, Modal } from 'react-bootstrap'
import './index.scss';
import SearchIcon from "@material-ui/icons/Search";
import "antd/dist/antd.min.css";
import { useSelector } from 'react-redux'
import { useFetchQuery, useStoreListQuery, useCreateMutation, useUpdateMutation, useAbortMutation, useStartMutation, useDeleteWorkerMutation } from '../../services/create_products';
import Moment from 'moment';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import { Tag } from 'antd';

function CreateProducts() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, isAdmin } = useSelector((state) => state.auth);
  const history = useHistory();
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const location = useLocation();
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15


  const [getSearchId, setSearchId] = useState("")
  const [getNewModal, setNewModal] = useState(false)
  const [getOthersModal, setOthersModal] = useState(false)
  const [getName, setName] = useState('')
  const [getUsername, setUsername] = useState('')
  const [getReference, setReference] = useState('')

  const othersUserData = {
    name: getName,
    username: getUsername,
    reference: getReference
  }

  const dataInfo = useFetchQuery({
    page: page,
    size: limit,
    searchid: getSearchId,
    userId: userId,
    userToken: userToken,
    userRole: userRole
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const results = dataInfo?.data?.results;
  const pagination = dataInfo?.data?.pagination;


  const storeListData = useStoreListQuery({
    page: page,
    size: limit,
    searchid: getSearchId,
    userId: userId,
    userToken: userToken,
    userRole: userRole
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const activeStore = storeListData?.data?.stores


  const [createUser, createresult] = useCreateMutation()
  const [deleteworker, deleteresult] = useDeleteWorkerMutation()
  const [abortquery, abortqueryresult] = useCreateMutation()
  const [startquery, startqueryresult] = useStartMutation()
  const [getJobType, setJobType] = useState("")
  const [getWorker, setWorker] = useState("")

  async function createFunction(data) {
    createUser({
      type: getJobType,
      worker: getWorker,
      from_store: data?.from_store,
      to_store: data?.to_store,
      limits: data?.limits,
      userId: userId,
      userToken: userToken,
      userRole: userRole
    })
  }

  function ebtidaEModal() {
    setNewModal(true)
  }
  function closeNewModal() {
    setNewModal(false)
  }

  useEffect(() => {
    if (createresult?.isSuccess) {
      setNewModal(false)
      setOthersModal(false)
      reset()
    }

  }, [createresult]);


  function getLoadingProgress(thisLoading, thisFetching) {
    if (thisLoading) {
      return true
    } else if (thisFetching) {
      return true
    } else {
      return false
    }
  }

  function getStatusName(status) {
    if (status === 'finished') {
      return { color: 'success', name: 'Finished', variant: 'outlined' }
    } else if (status === 'in_progress') {
      return { color: 'warning', name: 'In-progress', variant: 'outlined' }
    } else if (status === 'cancelled') {
      return { color: 'error', name: 'Cancelled', variant: 'outlined' }
    } else {
      return { color: 'info', name: 'Start', variant: 'outlined' }
    }
  }

  function buttonDisabled(status, worker, _id, thisId) {
    if (startqueryresult?.isLoading && startqueryresult?.originalArgs?.id === thisId) {
      return true
    } else {
      if (status === 'finished') {
        return true
      } else if (status === 'in_progress') {
        return true
      } else if (!worker) {
        return true
      } else if (!_id) {
        return true
      } else if (status === 'cancelled') {
        return true
      } else {
        return false
      }
    }
  }

  const numbers = Array.from({ length: 80 }, (_, index) => index + 1);

  return (
    <div className="tabel_cards">

      <Modal className="user-page-modal" show={getNewModal} onHide={closeNewModal} animation={false}>
        {/* <Modal.Header closeButton>
          <Modal.Title>New</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>

          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={closeNewModal}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>New</h5>
            </div>
          </div>
          <form onSubmit={handleSubmit(createFunction)}>
            {createresult?.error?.data?.error &&
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px' }}>
                <span className="alert alert-danger">{createresult?.error?.data?.error}</span>
              </div>
            }
            <div className="row">


              <div className="col-md-12">
                <div className="form-group">
                  <label>Store</label>
                  <select style={{ height: '45px' }} className='form-control' id="from_store"
                    aria-invalid={errors.from_store ? "true" : "false"}
                    {...register('from_store', { required: false })}>
                    <option selected disabled>Select</option>
                    {activeStore?.map((item, i) =>
                      <option value={item._id}>{item.name}</option>
                    )}
                  </select>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Type</label>
                  <select
                    style={{ height: '45px' }} onChange={(e) => setJobType(e.target.value)}
                    className='form-control'>
                    <option selected disabled>Select</option>
                    <option value={'create'}>Create Products</option>
                    <option value={'refetch'}>Refetch Products</option>
                  </select>
                </div>
              </div>


              <div className="col-md-12">
                <div className="form-group">
                  <label>Worker</label>
                  <select
                    required
                    style={{ height: '45px' }} onChange={(e) => setWorker(e.target.value)}
                    className='form-control'>
                    <option selected disabled>Select</option>
                    {numbers.map(number => (
                      <option value={`w${number?.toString().padStart(2, '0')}`}>
                        {/* {number < 10 ? `0${number}` : `${number}`} */}
                        worker{number?.toString().padStart(2, '0')}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Limits</label>
                  <input style={{ height: '45px' }} type="text" className="form-control"
                    id="limits"
                    aria-invalid={errors.limits ? "true" : "false"}
                    {...register('limits', { required: false })}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                  <Button
                    disabled={createresult.isLoading ? true : false}
                    color="info"
                    variant="contained"
                    size="small"
                    type="submit">
                    {createresult.isLoading ? 'Loading...' : 'Create'}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>


      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="newFlexPagi">
          <div className="col-md-12">
            <div className="storeCardB">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap' }}>
                <div>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>
                <div className="Order-Pagination">

                  <Pagination
                    className='list-paginatiion-table'
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    disabled={dataInfo?.isLoading ? true : false}
                    renderItem={(item) => (
                      <PaginationItem
                        component={Link}
                        to={{
                          pathname: `${window.location.pathname}`,
                          search: `?page=${parseInt(item.page)}`
                        }}
                        {...item}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="storeCardB">
            <div className="updateButton">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ position: 'relative', marginRight: '10px' }}>
                  <input style={{ borderRadius: '100px' }} className="form-control table_search" placeholder="Search..." onChange={(e) => setSearchId(e.target.value)} />
                  {/* {dataInfo?.isFetching &&
                    <Spinner style={{ width: '1rem', height: '1rem', fontSize: '7px', position: 'absolute', top: '12px', right: '40px', color: '#cbcbcb' }} animation="border" />
                  } */}
                  <SearchIcon className="table_search_icon" />
                </div>
              </div>
              <div className="table_search_card">
                <div>
                  <Button
                    // style={{background: '#4e5053'}}
                    style={{ border: '0.5px solid #4e5053', color: '#4e5053' }}
                    variant="outlined"
                    size="small"
                    onClick={() => ebtidaEModal()}>
                    Add New
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="tabelOverflow" style={{ position: 'relative' }}>
            {getLoadingProgress(dataInfo?.isLoading, dataInfo?.isFetching) &&
              <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                <LinearProgress style={{ height: '2px' }} />
              </div>
            }
            <table className="table">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Worker</th>
                  <th>Store</th>
                  <th>Limit</th>
                  <th>Processed</th>
                  <th>Updated At</th>
                  <th className="sticky-column" style={{ textAlign: 'right' }}>
                    Status
                  </th>
                </tr>
              </thead>
              {results?.length === 0 ?
                <h5 className="data_not-found">No data available</h5>
                : null}
              <tbody>
                {results?.map((item, i) =>
                  <tr key={i}>
                    <td>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* <IconButton
                          style={{ border: '1px solid #222d451c' }}
                          size="small"
                          color='error'
                          disabled={true}
                          // disabled={deleteresult?.isLoading && deleteresult?.originalArgs?.id === item._id}
                          onClick={() => {
                            if (window.confirm('Are you sure to delete?'))
                              deleteworker({
                                id: item._id,
                                userId: userId,
                                userToken: userToken,
                                userRole: userRole
                              })
                          }
                          }
                        >
                          <CloseIcon style={{ fontSize: '12px' }} />
                        </IconButton> */}
                        <div><Tag>{item.type}</Tag></div>
                      </div>
                    </td>
                    <td>
                      <div><Tag>{item.worker}</Tag></div>
                    </td>
                    <td>
                      {item.from_store &&
                        <div><Tag>{item.from_store?.name} - {item.from_store?._id}</Tag></div>
                      }
                    </td>
                    <td>
                      <div>{Number(item.limits) - Number(item.offset)}</div>
                    </td>
                    <td>
                      <div>{item.processed}</div>
                    </td>
                    <td>
                      <Tag>
                        {Moment(item?.createdAt).format('llll')}
                      </Tag>
                    </td>
                    <td className="sticky-column" style={{ textAlign: 'right' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>

                        <Button
                          style={{ margin: '0px 10px', textTransform: 'capitalize' }}
                          disabled={buttonDisabled(item.status, item.worker, item.from_store?._id, item._id)}
                          color={getStatusName(item.status)?.color}
                          variant={getStatusName(item.status)?.variant}
                          size="small"
                          type="submit"
                          onClick={(() => startquery({
                            id: item._id,
                            from_store: item.from_store,
                            to_store: item.to_store,
                            worker: item.worker,
                            type: item.type,
                            userId: userId,
                            userToken: userToken,
                            userRole: userRole
                          }))}
                        >
                          <small>
                            {getStatusName(item.status)?.name}
                            {item.status === 'in_progress' &&
                              <img src='/2.gif' style={{ width: '15px', margin: '0px 5px' }} />
                            }
                          </small>
                        </Button>

                        <Button
                          style={{ textTransform: 'capitalize' }}
                          disabled={item.status === 'pending' || (startqueryresult?.isLoading && startqueryresult?.originalArgs?.id === item._id)}
                          color="error"
                          variant="outlined"
                          size="small"
                          type="submit"
                          onClick={(() => (startquery({
                            id: item._id,
                            type: item.type,
                            worker: item.worker,
                            reset: true,
                            thread_id: item.thread_id,
                            userId: userId,
                            userToken: userToken,
                            userRole: userRole
                          })))}
                        >
                          <small>Reset</small>
                        </Button>
                      </div>
                    </td>
                  </tr>
                )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {!dataInfo?.isLoading &&
        <>
          {dataInfo?.error?.originalStatus === 403 &&
            <div className="row reject-bottom-message" style={{ margin: 0, padding: 0, justifyContent: 'center' }}>
              <div className="col-md-6" style={{ padding: 0 }}>
                <div className='alert alert-warning' style={{ textAlign: 'center' }}>{dataInfo?.error?.data}</div>
              </div>
            </div>
          }
        </>
      }
    </div>
  );
}

export default CreateProducts;
