import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import CloseIcon from "@material-ui/icons/Close";
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
function ImageUpdate({ getImageUpdateModal, closeImageUpdate, setUpdateImage, updatefileresult, updateBookImage, getUpdateImage }) {

  return (
    <>
      <Modal
        className="image-update-modal"
        show={getImageUpdateModal}
        onHide={closeImageUpdate}
        animation={false}
        backdrop={updatefileresult?.isLoading ? 'static' : 'dynamic'}
      >
        <IconButton
          className="close-button"
          onClick={updatefileresult?.isLoading ? '' : closeImageUpdate}
        >
          <CloseIcon />
        </IconButton>
        <Modal.Body>

          <div className="row">
            <div className="col-md-12">
              <h5>Import CSV</h5>
              <br />
            </div>

            <div className="col-md-12">
              {updatefileresult?.error?.data?.error &&
                <div className='alert alert-danger' style={{ textAlign: 'center' }}>{updatefileresult?.error?.data?.error}</div>
              }
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <input multiple type="file" className="form-control dlrdflInput"
                  onChange={(e) => setUpdateImage(e.target.files[0])} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group" style={{ textAlign: 'center' }}>
                {updatefileresult.isLoading ?
                  <>
                    <Spinner style={{ width: '2rem', height: '2rem', fontSize: '7px', marginBottom: 10 }} animation="border" />
                    <h5>uploading... don't close or reload the browser </h5>
                  </>

                  :
                  <Button
                    color="info"
                    variant="contained"
                    size="small"
                    disabled={getUpdateImage ? false : true}
                    onClick={updateBookImage}>Update</Button>}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ImageUpdate;
