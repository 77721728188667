import React, { useState, useEffect, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Login from './components/Login';
import ChangePassword from './components/ChangePassword';
import Admin from './Private/admin';
import Team from "./components/Team";
import GoogleMerchant from "./components/GoogleMerchant";
import GoogleMerchants from "./components/GoogleMerchants";
import ProductsImport from "./components/ProductsImport";
import ProductsAction from "./components/ProductsAction";
import Products from "./components/Products";
import Product from "./components/Product";
import Stores from "./components/Stores";
import Store from "./components/Store";
import CreateProducts from "./components/CreateProducts";
import NotFound from './NotFound';
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
            <Admin component={GoogleMerchants} />
          </Route>
          <Route exact path="/workers">
            <Admin component={CreateProducts} />
          </Route>
          <Route exact path="/stores">
            <Admin component={Stores} />
          </Route>
          <Route exact path="/store/:id">
            <Admin component={Store} />
          </Route>
          <Route exact path="/store/:storeId/:import">
            <Admin component={ProductsImport} />
          </Route>
          <Route exact path="/google-merchant/:action/:merchant_id">
            <Admin component={ProductsAction} />
          </Route>
          <Route exact path="/products">
            <Admin component={Products} />
          </Route>
          <Route exact path="/google-merchant/:merchant_id">
            <Admin component={GoogleMerchant} />
          </Route>
          <Route exact path="/google-merchants">
            <Admin component={GoogleMerchants} />
          </Route>
          <Route exact path="/product/:id">
            <Admin component={Product} />
          </Route>
          <Route exact path="/change-password">
            {/* <ChangePassword /> */}
            <Admin component={ChangePassword} />
          </Route>
          <Route exact path="/team">
            {/* <Users /> */}
            <Admin component={Team} />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route>
            {/* <NotFound /> */}
            <Admin component={NotFound} />
          </Route>
        </Switch>
      </Router>

    </div>
  );
}

export default App;
