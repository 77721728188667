import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import './index.scss';
import { useShowQuery, useUpdateMutation, useDeleteMutation, useFetchPriceMutation } from '../../services/products';
import { useSelector } from "react-redux";
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { CKEditor } from 'ckeditor4-react';
import EditIcon from '@mui/icons-material/Edit';
import LanguageIcon from '@mui/icons-material/Language';
import CloseIcon from '@mui/icons-material/Close';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useCreateUserMutation } from '../../services/google_merchant';
import StarPurple500OutlinedIcon from '@mui/icons-material/StarPurple500Outlined';
import Loader from '../../Helper/Loader';
import { Tag, Switch } from 'antd';
import { Switch as SwitchButton } from 'antd';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  imageList: {
    "&::-webkit-scrollbar": {
      display: "none"
    }, /* Chrome */
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));

function Product() {
  const classes = useStyles();
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { id } = useParams();

  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  const FetchPost = useShowQuery({ id: id, userId: userId, userToken: userToken, admin: admin }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const PostData = FetchPost?.data?.product;

  const [deleteList, deleteresult] = useDeleteMutation()


  const [getEditShow, setEditShow] = useState(false);


  const [getTitle, setTitle] = useState("")
  const [getCountry, setCountry] = useState("")
  const [getColor, setColor] = useState("")
  const [getWeight, setWeight] = useState(0)
  const [getQuantity, setQuantity] = useState("")
  const [getBrand, setBrand] = useState("")
  const [getCondition, setCondition] = useState("")
  const [getInStock, setInStock] = useState("")
  const [getTags, setTags] = useState("")
  const [getPrice, setPrice] = useState(0)
  const [getCategories, setCategories] = useState("")
  const [getFeatures, setFeatures] = useState("")
  const [getDetails, setDetails] = useState("")
  const [getImageUrls, setImageUrls] = useState("")
  const [getProductUrl, setProductUrl] = useState("")
  const [getUID, setUID] = useState("")
  const [getDeliveryDays, setDeliveryDays] = useState(0)
  const [getId, setId] = useState()


  function handleCloseEdit() {
    setEditShow(false)
  }
  function handleOpenEdit(data) {
    setEditShow(true)
    setTitle(data.title)
    setUID(data.asin)
    setCountry(data.country)
    setColor(data.color)
    setWeight(data.weight)
    setQuantity(data.quantity)
    setBrand(data.brand)
    setCondition(data.condition)
    setInStock(data.in_stock)
    setPrice(data.price)
    setCategories(data.category)
    setTags(data.tags)
    setFeatures(data.features)
    setDetails(data.details)
    setImageUrls(data.images)
    setProductUrl(data.product_url)
    setDeliveryDays(data.estimated_delivery)
    setId(data.id)

  }


  const [updatePost, updateresult] = useUpdateMutation()

  useEffect(() => {
    if (updateresult?.isSuccess) {
      setEditShow(false)
    }

  }, [updateresult]);


  const postFeatures = (evt) => {
    const data = evt.editor.getData();
    setFeatures(data);
  }

  const postDetails = (evt) => {
    const data = evt.editor.getData();
    setDetails(data);
  }

  const updateData = {
    title: getTitle,
    country: getCountry,
    color: getColor,
    weight: getWeight,
    quantity: getQuantity,
    brand: getBrand,
    condition: getCondition,
    in_stock: getInStock,
    tags: getTags,
    price: getPrice,
    category: getCategories,
    features: getFeatures,
    details: getDetails,
    images: getImageUrls,
    product_url: getProductUrl,
    estimated_delivery: getDeliveryDays,
    userId: userId,
    userToken: userToken,
    admin: admin,
    id: getId,
    update: 'update'
  }



  function productTitle(title) {
    return title?.replace(/[^A-Za-z0-9]/g, ' ')?.replace(/ /g, '-')
  }



  function disabledPublish() {
    if (deleteresult?.isLoading) {
      return true
    } else if (FetchPost?.isLoading) {
      return true
    } else {
      return false
    }
  }

  const [creategmNew, creategmresult] = useCreateUserMutation()
  const [fetchprice, fetchpriceresult] = useFetchPriceMutation()


  function productTitle(title) {
    return title?.replace(/[^A-Za-z0-9]/g, ' ')?.replace(/ /g, '-')
  }



  const [getGoogleSync, setGoogleSync] = useState(false);
  const [getDomain, setDomain] = useState('');
  const [getTargetCountry, setTargetCountry] = useState('');
  const [getGoogleData, setDataForG] = useState();
  const [getMerchant, setMerchant] = useState();

  function hideGoogleSync() {
    setGoogleSync(false)
  }

  function googleSyncButton(data) {
    setGoogleSync(true)
    setDataForG(data)
  }

  useEffect(() => {
    if (creategmresult.isSuccess) {
      setGoogleSync(false)
    }
  }, [creategmresult]);



  function functionIdLink(id, pid, store_id) {
    if (Number(store_id) === 3) {
      return pid
    } else {
      return id
    }
  }


  return (
    <div className="Order-style Post-style">
      <div style={{ display: 'none' }}>
        <CKEditor
          style={{ display: 'none' }}
          data={``}
          config={{
            extraPlugins: "justify,showblocks",
            toolbar: [
              ['Source'],
              ['Styles', 'Format', 'Font', 'FontSize'],
              ['Bold', 'Italic'],
              ['Undo', 'Redo'],
              ['EasyImageUpload'],
              ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
              ['Maximize']
            ],
            height: '220px',
            allowedContent: false,
            direction: 'rtl',
            language: 'en',
            pasteFromWordRemoveFontStyles: false
          }}
          id="template_html"
        />
      </div>




      <Modal className="user-page-modal" show={getGoogleSync} onHide={hideGoogleSync} animation={false}>
        <Modal.Body>
          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={hideGoogleSync}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>Update</h5>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Merchant ID</label>
                <input className="form-control" required defaultValue={getMerchant} onChange={(e) => setMerchant(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Domain</label>
                <input className="form-control" defaultValue={getDomain} onChange={(e) => setDomain(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Target Country</label>
                <select className="form-control" onChange={(e) => fetchprice({
                  id: getGoogleData?.id,
                  pcountry: getGoogleData?.country,
                  tcountry: e.target.value,
                  userId: userId,
                  userToken: userToken,
                  admin: admin,
                })}>
                  <option value={''} selected disabled>Select</option>
                  <option value={'AE'}>UAE</option>
                  <option value={'OM'}>Oman</option>
                  <option value={'KW'}>Kuwait</option>
                  <option value={'SA'}>Saudi</option>
                  <option value={'CA'}>Canada</option>
                  <option value={'GB'}>UK</option>
                  <option value={'BH'}>Bahrain</option>
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                {fetchpriceresult?.isLoading ?
                  <div style={{ display: 'flex', alignItems: 'center' }}><span>Fetching</span>  <Loader /></div> :
                  <>
                    <div>Target Country: {fetchpriceresult?.data?.product?.prices?.country}</div>
                    <div>Price: {fetchpriceresult?.data?.product?.prices?.currency} {fetchpriceresult?.data?.product?.prices?.value}</div>
                    <div>Merchant ID: {getMerchant}</div>
                    <div>Domain: https://{getDomain}</div>
                  </>
                }
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                <Button
                  disabled={!fetchpriceresult?.data?.product}
                  color="info"
                  variant="contained"
                  size="small"
                  onClick={() => creategmNew({
                    merchantId: getMerchant,
                    offerId: fetchpriceresult?.data?.product?.id,
                    title: fetchpriceresult?.data?.product?.title,
                    description: fetchpriceresult?.data?.product?.details,
                    link: `https://${getDomain}/product/${fetchpriceresult?.data?.product?.id}`,
                    imageLink: fetchpriceresult?.data?.product?.image,
                    targetCountry: fetchpriceresult?.data?.product?.prices?.country,
                    availability: fetchpriceresult?.data?.product?.in_stock,
                    condition: fetchpriceresult?.data?.product?.condition,
                    price: fetchpriceresult?.data?.product?.prices?.value,
                    currency: fetchpriceresult?.data?.product?.prices?.currency,
                    userId: userId,
                    userToken: userToken,
                    admin: admin
                  })}
                >
                  {creategmresult?.isLoading ? <Loader /> : 'Update'}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>






      <Modal className="new-post-modal" show={getEditShow} onHide={handleCloseEdit} centered animation={true}>
        <Modal.Body>

          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={handleCloseEdit}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>Update Product</h5>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Title</label>
                    <input type="text" className="form-control" defaultValue={getTitle} onChange={(e) => setTitle(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Country</label>
                    <select className="form-control" onChange={(e) => setCountry(e.target.value)}>
                      <option value={getCountry} selected>{getCountry}</option>
                      <option value={'AE'}>UAE</option>
                      <option value={'US'}>United State</option>
                      <option value={'UK'}>United Kingdom</option>

                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Price in USD</label>
                    <input type="number" className="form-control" defaultValue={getPrice} onChange={(e) => setPrice(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Weight in Grams</label>
                    <input type="number" className="form-control" defaultValue={getWeight} onChange={(e) => setWeight(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>UID</label>
                    <input type="text" disabled className="form-control" defaultValue={getUID} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Condition</label>
                    <input type="text" className="form-control" defaultValue={getCondition} onChange={(e) => setCondition(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Color</label>
                    <input type="text" className="form-control" defaultValue={getColor} onChange={(e) => setColor(e.target.value)} />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label>Brand</label>
                    <input type="text" className="form-control" defaultValue={getBrand} onChange={(e) => setBrand(e.target.value)} />
                  </div>
                </div>


                <div className="col-md-3">
                  <div className="form-group">
                    <label>In Stock</label>
                    <select className='form-control' onChange={(e) => setInStock(e.target.value)}>
                      <option selected value={getInStock}>{getInStock}</option>
                      <option value={'In Stock'}>In Stock</option>
                      <option value={'Out of Stock'}>Out of Stock</option>
                    </select>

                  </div>
                </div>



                <div className="col-md-3">
                  <div className="form-group">
                    <label>Categories</label>
                    <input type="text" className="form-control" defaultValue={getCategories} onChange={(e) => setCategories(e.target.value)} />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label>Tags</label>
                    <input type="text" className="form-control" defaultValue={getTags} onChange={(e) => setTags(e.target.value)} />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label>Quantity</label>
                    <input type="number" className="form-control" defaultValue={getQuantity} onChange={(e) => setQuantity(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Delivery in Days</label>
                    <input type="number" className="form-control" defaultValue={getDeliveryDays} onChange={(e) => setDeliveryDays(e.target.value)} />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Image urls</label>
                    <input type="text" className="form-control" defaultValue={getImageUrls} onChange={(e) => setImageUrls(e.target.value)} />
                  </div>
                </div>



                <div className="col-md-6">
                  <div className="form-group">
                    <label>Product URL</label>
                    <input type="text" className="form-control" defaultValue={getProductUrl} onChange={(e) => setProductUrl(e.target.value)} />
                  </div>
                </div>

              </div>
            </div>

            <div className="col-md-12">

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Features</label>
                    <CKEditor
                      data={``}
                      config={{
                        extraPlugins: "justify,showblocks",
                        toolbar: [
                          ['Source'],
                          ['Styles', 'Format', 'Font', 'FontSize'],
                          ['Bold', 'Italic'],
                          ['Undo', 'Redo'],
                          ['EasyImageUpload'],
                          ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
                          ['Maximize']
                        ],
                        height: '220px',
                        allowedContent: false,
                        direction: 'rtl',
                        language: 'en',
                        pasteFromWordRemoveFontStyles: false
                      }}
                      id="template_html"
                      onChange={postFeatures}
                      initData={getFeatures} />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label>Details</label>
                    <CKEditor
                      data={``}
                      config={{
                        extraPlugins: "justify,showblocks",
                        toolbar: [
                          ['Source'],
                          ['Styles', 'Format', 'Font', 'FontSize'],
                          ['Bold', 'Italic'],
                          ['Undo', 'Redo'],
                          ['EasyImageUpload'],
                          ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
                          ['Maximize']
                        ],
                        height: '220px',
                        allowedContent: false,
                        direction: 'rtl',
                        language: 'en',
                        pasteFromWordRemoveFontStyles: false
                      }}
                      id="template_html"
                      onChange={postDetails}
                      initData={getDetails} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Button disabled={updateresult?.isLoading ? true : false}
                      color="info"
                      variant="contained"
                      size="small"
                      onClick={() => { updatePost(updateData) }}
                    >
                      {updateresult?.isLoading ? 'Loading...' : 'Update'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>



      <div className="row" style={{ padding: 0, margin: 0 }}>
        <div className="col-md-6" style={{ display: 'flex', alignItems: 'center' }}>
          {/* <div className="bouncing-loader" style={{ justifyContent: 'left' }}>
              <div></div>
              <div></div>
              <div></div>
            </div> */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button onClick={handleBack}><ArrowBackOutlinedIcon /></Button>
          </div>
        </div>
        <div className="col-md-6" style={{ textAlign: 'right' }}>
        </div>
      </div>

      <br />
      <div style={{ borderTop: '1px solid #eeee', borderBottom: '1px solid #eee', padding: '10px 0px 10px 0px' }}>
        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12">
              <div className="post-value">{PostData?.title}</div>
          </div>
        </div>
        <br />
        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12">

            {PostData?.images ?
              <ImageList className={classes.imageList} cols={8}>
                {PostData?.images?.split(',').map((concept, i) =>
                  <ImageListItem className="Dashboard-books-card" key={i} style={{ height: 'auto', padding: '10px' }}>
                    <div className="img-list-card" style={{ position: 'relative', position: 'relative', border: '1px solid #eeeeee78' }}>
                      <img className="img-list-img" style={{ width: '100%', border: '0.2px solid #000', borderRadius: '10px' }} src={`${concept}`} />
                      <div className="Category-text" style={{ position: 'absolute', top: 0, left: '0px', zIndex: 999 }}>
                      </div>
                    </div>
                  </ImageListItem>
                )}
              </ImageList> :
              <div className="img-list-card" style={{ position: 'relative', position: 'relative', border: '1px solid #eeeeee78' }}>
                <img className="img-list-img" style={{ width: '100%', border: '0.2px solid #000', borderRadius: '10px' }} src={`${PostData?.image}`} />
              </div>
            }
          </div>
        </div>
      </div>

      <br />
      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12">
          <div>
            <div className="Order-Top_navbar">
              <div style={{ display: 'flex', whiteSpace: 'normal', width: '100%' }}>

                <div className="post-section">
                  <div className="row">
                    <div className="col-md-12">
                      {PostData &&
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', padding: '20px 0px 20px 0px' }}>
                          <div className="form-group" style={{ margin: 0 }}>
                            <div style={{ textAlign: 'start', padding: '0px' }}>Product Details #{PostData?.pid}</div>
                            <Tag style={{ textAlign: 'start' }}>Store Name {PostData?.store?.name} #{PostData?.store?._id}</Tag>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                            {/* <IconButton
                              disabled={FetchPost?.isLoading}
                              onClick={() => handleOpenEdit(PostData)}
                            >
                              <EditIcon style={{ fontSize: '12px' }} />
                            </IconButton> */}
                            <div style={{ margin: '0px 5px' }}>
                              <label>Status</label>
                              <Switch
                                style={{ margin: '0px 5px' }}
                                defaultChecked={PostData?.status}
                                onChange={(() =>
                                  deleteList({
                                    status_update: true,
                                    status: PostData?.status ? false : true,
                                    id: PostData?._id,
                                    userId: userId,
                                    userToken: userToken,
                                    admin: admin
                                  }))}
                              />
                            </div>
                            <div style={{ margin: '0px 5px' }}>
                              <label>google merchant</label>
                              <SwitchButton
                                style={{ margin: '0px 5px' }}
                                defaultChecked={PostData?.google_merchant === 0 ? false : true}
                                onChange={(() =>
                                  deleteList({
                                    google_merchant_update: true,
                                    google_merchant: PostData?.google_merchant === 0 ? 1 : 0,
                                    id: PostData?._id,
                                    userId: userId,
                                    userToken: userToken,
                                    admin: admin
                                  }))}
                              />
                            </div>
                            <IconButton
                              disabled={FetchPost?.isLoading}
                              style={{ margin: '0 10px' }}
                              onClick={() => window.open(`https://www.desertcart.com/products/${PostData.pid}`, '_blank').focus()}
                            >
                              <LanguageIcon style={{ fontSize: '22px' }} />
                            </IconButton>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                  <div className="post-card">
                    {FetchPost?.isLoading &&
                      <>
                        <Skeleton variant="rect" style={{ height: '12px' }} />
                        <Skeleton variant="rect" style={{ height: '12px' }} />
                        <Skeleton variant="rect" style={{ height: '12px', width: '50%' }} />
                        <Skeleton variant="rect" style={{ height: '12px', width: '50%' }} />
                        <Skeleton variant="rect" style={{ height: '12px', width: '20%' }} />
                      </>
                    }
                  </div>
                  {!FetchPost?.isLoading &&
                    <>
                      <div className="post-card">
                        <div className="post-title"><strong>UID</strong></div>
                        <div className="post-value">{PostData?.asin}</div>
                      </div>
                      <div className="post-card">
                        <div className="post-title"><strong>Price</strong></div>
                        <div className="post-value">{PostData?.price}</div>
                      </div>
                      <div className="post-card">
                        <div className="post-title"><strong>Currency</strong></div>
                        <div className="post-value">{PostData?.currency}</div>
                      </div>
                      <div className="post-card">
                        <div className="post-title"><strong>Country</strong></div>
                        <div className="post-value">{PostData?.country}</div>
                      </div>
                      <div className="post-card">
                        <div className="post-title"><strong>Brand</strong></div>
                        <div className="post-value">{PostData?.brand}</div>
                      </div>
                      <div className="post-card">
                        <div className="post-title"><strong>Condition</strong></div>
                        <div className="post-value">{PostData?.condition}</div>
                      </div>
                      <div className="post-card">
                        <div className="post-title"><strong>In Stock</strong></div>
                        <div className="post-value">{PostData?.in_stock ? 'In stock' : 'Out of stock'}</div>
                      </div>
                      <div className="post-card">
                        <div className="post-title"><strong>Categories</strong></div>
                        <div className="post-value">{PostData?.category}</div>
                      </div>
                      <div className="post-card">
                        <div className="post-title"><strong>weight</strong></div>
                        <div className="post-value">{PostData?.weight}&nbsp; {PostData?.weight_in}</div>
                      </div>
                      <div className="post-card">
                        <div className="post-title"><strong>Image Urls</strong></div>
                        <div className="post-value">{PostData?.images}</div>
                      </div>
                      <div className="post-card">
                        <div className="post-title"><strong>Features</strong></div>
                        <div className="post-value"
                          dangerouslySetInnerHTML={{
                            __html: PostData?.features
                          }}
                        >
                        </div>
                      </div>
                      <div className="post-card">
                        <div className="post-title"><strong>Details</strong></div>
                        <div className="post-value"
                          dangerouslySetInnerHTML={{
                            __html: PostData?.details
                          }}
                        >
                        </div>
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!FetchPost?.isLoading &&
        <>
          {FetchPost?.error?.originalStatus === 403 &&
            <div className="row reject-bottom-message" style={{ margin: 0, padding: 0, justifyContent: 'center' }}>
              <div className="col-md-6" style={{ padding: 0 }}>
                <div className='alert alert-warning' style={{ textAlign: 'center' }}>{FetchPost?.error?.data}</div>
              </div>
            </div>
          }
        </>
      }
    </div>
  );
}

export default Product;
