import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Modal } from 'react-bootstrap'
import './index.scss';
import "antd/dist/antd.min.css";
import { useStoreProductsQuery, useDeleteMutation, useCreateMutation, useUpdateMutation, useUpdateFileMutation, useRefetchProductsMutation, useRefetchIndexQuery, useRefetchUpdateMutation, useSplitProductsMutation } from '../../services/products';
import { useGetWorkerQuery, useCreateMutation as createWorkerFromStore, useAssignWorkersMutation, useStartMutation } from '../../services/create_products';
import { useSelector } from "react-redux";
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { CKEditor } from 'ckeditor4-react';
import ImageUpdate from "./ImageUpdate";
import CloseIcon from '@mui/icons-material/Close';
import parse from "html-react-parser";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import EditIcon from '@mui/icons-material/Edit';
import LanguageIcon from '@mui/icons-material/Language';
import LinearProgress from '@mui/material/LinearProgress';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { Tag } from 'antd';
import { margin } from '@mui/system';

function Store() {
  const { userId, userToken, userEmail, userName, status, admin, userRole } = useSelector((state) => state.auth);
  const location = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { id } = useParams();
  const [getSearchQuery, searchQuery] = useState("")
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15
  const product_status = new URLSearchParams(location.search).get('product_status') || 'all'

  const suppliersData = useStoreProductsQuery({ status: product_status, page: page, size: limit, userId: userId, userToken: userToken, admin: admin, searchquery: getSearchQuery, id: id }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const suppliers = suppliersData?.data?.products;
  const pagination = suppliersData?.data?.pagination;


  const { data: workerdata, refetch } = useGetWorkerQuery({ userId: userId, userToken: userToken, admin: admin, id: id }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const workerList = workerdata?.workers;

  const [createNew, createresult] = useCreateMutation()

  const [getSplitLimit, setSplitLimit] = useState();
  const [getSplitStore, setSplitStore] = useState();
  const [splitproducts, splitresult] = useSplitProductsMutation()

  const refetchData = useRefetchIndexQuery({ userId: userId, userToken: userToken, admin: admin, store_id: id }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const [refetchcreate, refetchresult] = useRefetchProductsMutation()
  const [refetchupdate, refetchupdateresult] = useRefetchUpdateMutation()

  const [show, setShow] = useState(false);
  const [getEditShow, setEditShow] = useState(false);



  const workerExist = workerList?.filter(item => (item.from_store?._id === id))
  const workerinProgress = workerExist?.filter(item => (item.status === 'in_progress'))





  const [admin_access, setAdminAccess] = useState([]);


  function getNameById(idd) {
    const filteredObjects = workerList?.filter(item => item.from_store?._id === idd);
    setAdminAccess(filteredObjects?.map(item => item.worker))
  }


  const handleAdminAccess = (event) => {
    const isChecked = event.target.checked;
    const value = event.target.value;

    if (isChecked) {
      if (!admin_access) {
        setAdminAccess([value]);
      } else {
        setAdminAccess(prevState => [...prevState, value]);
      }
    } else {
      setAdminAccess(prevState => prevState.filter(item => item !== value));
    }
  };


  useEffect(() => {
    getNameById(id)
  }, [workerdata]);


  const [getTitle, setTitle] = useState("")
  const [getCountry, setCountry] = useState("")
  const [getColor, setColor] = useState("")
  const [getWeight, setWeight] = useState(0)
  const [getQuantity, setQuantity] = useState("")
  const [getBrand, setBrand] = useState("")
  const [getCondition, setCondition] = useState("")
  const [getInStock, setInStock] = useState("")
  const [getTags, setTags] = useState("")
  const [getPrice, setPrice] = useState(0)
  const [getCategories, setCategories] = useState("")
  const [getFeatures, setFeatures] = useState("")
  const [getDetails, setDetails] = useState("")
  const [getImageUrls, setImageUrls] = useState("")
  const [getMainImage, setMainImage] = useState("")
  const [getProductUrl, setProductUrl] = useState("")
  const [getUID, setUID] = useState("")
  const [getDeliveryDays, setDeliveryDays] = useState(0)
  const [getId, setId] = useState()


  function handleCloseEdit() {
    setEditShow(false)
  }
  function handleOpenEdit(data) {
    setEditShow(true)
    setTitle(data.title)
    setUID(data.asin)
    setCountry(data.country)
    setColor(data.color)
    setWeight(data.weight)
    setQuantity(data.quantity)
    setBrand(data.brand)
    setCondition(data.condition)
    setInStock(data.in_stock)
    setPrice(data.price)
    setCategories(data.category)
    setTags(data.tags)
    setFeatures(data.features)
    setDetails(data.details)
    setImageUrls(data.images)
    setMainImage(data.image)
    setProductUrl(data.product_url)
    setDeliveryDays(data.estimated_delivery)
    setId(data.id)

  }

  function handleClose() {
    setShow(false)
  }
  function handleOpen() {
    setShow(true)
  }



  const [updatePost, updateresult] = useUpdateMutation()

  useEffect(() => {
    if (updateresult?.isSuccess) {
      setEditShow(false)
    }
    if (createresult?.isSuccess) {
      setShow(false)
    }
  }, [updateresult, createresult]);


  const postFeatures = (evt) => {
    const data = evt.editor.getData();
    setFeatures(data);
  }

  const postDetails = (evt) => {
    const data = evt.editor.getData();
    setDetails(data);
  }

  function createNewPost(data) {
    createNew({
      title: data.title,
      country: data.country,
      asin: data.asin,
      color: data.color,
      weight: data.weight,
      quantity: data.quantity,
      brand: data.brand,
      condition: data.condition,
      in_stock: data.in_stock,
      price: data.price,
      category: data.category,
      tags: data.tags,
      images: data.images,
      image: data.main_image,
      product_url: data.product_url,
      estimated_delivery: data.estimated_delivery,
      store_id: id,
      features: getFeatures,
      details: getDetails,
      userId: userId,
      userToken: userToken,
      admin: admin
    })
  }

  const updateData = {
    title: getTitle,
    country: getCountry,
    color: getColor,
    weight: getWeight,
    quantity: getQuantity,
    brand: getBrand,
    condition: getCondition,
    in_stock: getInStock,
    tags: getTags,
    price: getPrice,
    category: getCategories,
    features: getFeatures,
    details: getDetails,
    images: getImageUrls,
    image: getMainImage,
    product_url: getProductUrl,
    estimated_delivery: getDeliveryDays,
    userId: userId,
    userToken: userToken,
    admin: admin,
    id: getId,
    update: 'update'
  }


  const [updatefile, updatefileresult] = useUpdateFileMutation()
  const [getImageUpdateModal, setImageUpdateModal] = useState(false);
  const [getUpdateImage, setUpdateImage] = useState();
  function updateBookImage() {
    updatefile({
      image_file: getUpdateImage,
      userId: userId,
      userToken: userToken,
      userRole: admin,
      store_id: id,
    })
  }


  function imageUpdate(data) {
    setImageUpdateModal(true)
  }
  function closeImageUpdate() {
    setImageUpdateModal(false)
  }

  useEffect(() => {
    if (updatefileresult.isSuccess) {
      setImageUpdateModal(false)
    }
    if (createresult?.isSuccess) {
      reset()
    }
  }, [updatefileresult, createresult]);


  function getLoadingProgress(thisLoading, thisFetching) {
    if (thisLoading) {
      return true
    } else if (thisFetching) {
      return true
    } else {
      return false
    }
  }

  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };



  const [getWorkerDropdown, setWorkerDropdown] = React.useState(false);

  const handleOpenChange = React.useCallback((event, isOpen) => {
    setWorkerDropdown(isOpen);
  }, []);

  const [createUser, createresultt] = useAssignWorkersMutation()
  const [getJobType, setJobType] = useState("")
  const [getWorker, setWorker] = useState("")
  async function createFunction(data) {
    createUser({
      id: id,
      type: getJobType,
      workers: admin_access,
      userId: userId,
      userToken: userToken,
      userRole: userRole
    })
  }

  useEffect(() => {
    if (createresultt?.isSuccess) {
      refetch()
      reset()
      setWorkerDropdown(false)
    }

  }, [createresultt, createresultt?.isSuccess]);

  const numbers = Array.from({ length: 20 }, (_, index) => index + 1);

  const [startquery, startqueryresult] = useStartMutation()

  function buttonDisabled(status, worker, _id) {
    if (startqueryresult?.isLoading) {
      return true
    } else {
      if (status === 'finished') {
        return true
      } else if (status === 'in_progress') {
        return true
      } else if (!worker) {
        return true
      } else if (!_id) {
        return true
      } else if (status === 'cancelled') {
        return true
      } else {
        return false
      }
    }
  }

  useEffect(() => {
    if (startqueryresult?.isSuccess) {
      refetch()
    }

  }, [startqueryresult, startqueryresult?.isSuccess]);
  

  function getStatusName(status) {
    if (status === 'finished') {
      return { color: 'success', name: 'Finished', variant: 'outlined' }
    } else if (status === 'in_progress') {
      return { color: 'warning', name: 'In-progress', variant: 'outlined' }
    } else if (status === 'cancelled') {
      return { color: 'error', name: 'Cancelled', variant: 'outlined' }
    } else {
      return { color: 'info', name: 'Start', variant: 'outlined' }
    }
  }




  return (
    <div className="tabel_cards">



      <ImageUpdate
        getImageUpdateModal={getImageUpdateModal}
        closeImageUpdate={closeImageUpdate}
        setUpdateImage={setUpdateImage}
        updatefileresult={updatefileresult}
        updateBookImage={updateBookImage}
        getUpdateImage={getUpdateImage}
      />


      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="newFlexPagi">
          <div className="col-md-12">
            <div className="storeCardB">
              <div
                style={{
                  display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap'
                }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button variant='outlined' size='small' onClick={handleBack}><ArrowBackOutlinedIcon style={{ fontSize: '1rem' }} /></Button>
                  <div style={{ margin: '0px 10px' }}>
                    Showing {pagination?.totalCounts && pagination?.totalCounts} entries
                  </div>
                  <div style={{ margin: '0px 10px' }}>
                    <Dropdown >
                      <MenuButton
                        style={{ padding: '6px 8px', border: 'none', borderRadius: '6px', fontWeight: 500 }}
                        className="Header-button-connect">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span>Split</span>
                          <ArrowDropDownIcon style={{ fontSize: '1.5rem', margin: '0 3px' }} />
                        </div>
                      </MenuButton>
                      <Menu style={{ padding: '6px 15px', width: '380px', maxWidth: '100%' }}>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>Limit (min 100)</label>
                                  <input
                                    required
                                    onChange={(e) => setSplitLimit(e.target.value)}
                                    type="text"
                                    className="form-control" />
                                </div>
                              </div>
                              {/* <div className="col-md-6">
                                <div className="form-group">
                                  <label>Store Number</label>
                                  <input
                                    onChange={(e) => setSplitStore(e.target.value)}
                                    type="text"
                                    className="form-control" />
                                </div>
                              </div> */}
                              {suppliers?.length > 0 &&
                                <div className="col-md-6">
                                  <Button
                                    disabled={splitresult?.isLoading}
                                    color='warning'
                                    variant="outlined"
                                    size="small"
                                    onClick={() => splitproducts({
                                      from_store: id,
                                      to_store: getSplitStore,
                                      limit: getSplitLimit,
                                      userId: userId,
                                      userToken: userToken,
                                      admin: admin
                                    })}
                                  >
                                    Start
                                  </Button>
                                </div>
                              }


                              {splitresult?.isSuccess &&
                                <div className="col-md-12">
                                  <div>Store Name &nbsp; {splitresult?.data?.store?.name}</div>
                                  <div>Store Country &nbsp; {splitresult?.data?.store?.country}</div>
                                </div>
                              }


                            </div>
                          </div>
                        </div>
                        <MenuItem disabled ></MenuItem>
                      </Menu>
                    </Dropdown>
                  </div>
                </div>

                <div className="Order-Pagination">
                  <Pagination
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px 0px 0px 0px' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    variant="outlined"
                    renderItem={(item) => (
                      <PaginationItem
                        component={Link}
                        to={{ pathname: `${window.location.pathname}`, search: parseInt(item.page) === 1 ? product_status && `product_status=${product_status}` : `?page=${parseInt(item.page)}${product_status && `&product_status=${product_status}`}` }}
                        {...item}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="storeCardB">
            <div className="updateButton">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Dropdown>
                  <MenuButton
                    size='sm'
                    variant='outlined'
                    style={{ padding: '6px 8px', borderRadius: '6px', fontWeight: 500 }}
                    className="Header-button-connect">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <small style={{ textTransform: 'capitalize' }}>Filter:&nbsp; {product_status}</small>
                      <ArrowDropDownIcon style={{ fontSize: '1.5rem', margin: '0 3px' }} />
                    </div>
                  </MenuButton>
                  <Menu>
                    <MenuItem disabled={product_status === 'all'} component={Link} to={`${window.location.pathname}?product_status=all`}>All Products</MenuItem>
                    <MenuItem disabled={product_status === 'active'} component={Link} to={`${window.location.pathname}?product_status=active`}>Active Products</MenuItem>
                    <MenuItem disabled={product_status === 'inactive'} component={Link} to={`${window.location.pathname}?product_status=inactive`}>Inactive Products</MenuItem>
                  </Menu>
                </Dropdown>



                <Dropdown open={getWorkerDropdown} onOpenChange={handleOpenChange}>
                  <MenuButton
                    size='sm'
                    variant='outlined'
                    style={{ padding: '6px 8px', margin: '0px 10px'  }}
                    className="Header-button-connect">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <small style={{ textTransform: 'capitalize' }}>Start Worker</small>
                      <ArrowDropDownIcon style={{ fontSize: '1.5rem', margin: '0 3px' }} />
                    </div>
                  </MenuButton>
                  <Menu style={{ width: '440px', maxWidth: '100%', padding: '10px' }}>
                    <form onSubmit={handleSubmit(createFunction)}>
                      {createresultt?.error?.data?.error &&
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px' }}>
                          <Tag color="error">{createresultt?.error?.data?.error}</Tag>
                        </div>
                      }
                      {createresultt?.data?.success &&
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px' }}>
                          <Tag color="success">{createresultt?.data?.success}</Tag>
                        </div>
                      }
                      <div className="row">

                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Type</label>
                            <select
                              required
                              style={{ height: '45px' }} onChange={(e) => setJobType(e.target.value)}
                              className='form-control'>
                              <option selected disabled value={''}>Select</option>
                              <option value={'create'}>Create Products</option>
                              <option value={'refetch'}>Refetch Products</option>
                            </select>
                          </div>
                        </div>


                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Workers</label>

                            <div style={{ height: '300px', overflow: 'auto' }}>
                              {workerList?.map(number => (
                                <FormControlLabel
                                  style={{ display: 'flex', width: '100%' }}
                                  disabled={number?.from_store?._id !== id && number?.from_store?._id}
                                  onChange={handleAdminAccess}
                                  control={<Checkbox size='small' defaultChecked={number?.from_store?._id === id} name='admin_access' value={number?.worker} />}
                                  label={
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', fontSize: '12px' }}>
                                      <div>worker - {number?.worker}</div>
                                     
                                      <Tag style={{ margin: '0px 10px' }}>{`${number?.processed}`}</Tag>


                                      {number?.from_store?._id === id &&
                                        <Button
                                          style={{ margin: '0px 10px', textTransform: 'capitalize' }}
                                          disabled={buttonDisabled(number.status, number.worker, number.from_store?._id)}
                                          color={getStatusName(number.status)?.color}
                                          variant={getStatusName(number.status)?.variant}
                                          size="small"
                                          type="submit"
                                          onClick={(() => startquery({
                                            id: number._id,
                                            from_store: number.from_store,
                                            to_store: number.to_store,
                                            worker: number.worker,
                                            type: number.type,
                                            userId: userId,
                                            userToken: userToken,
                                            userRole: userRole
                                          }))}
                                        >
                                          <small>
                                            {getStatusName(number.status)?.name}
                                            {number.status === 'in_progress' &&
                                              <img src='/2.gif' style={{ width: '15px', margin: '0px 5px' }} />
                                            }
                                          </small>
                                        </Button>
                                      }

                                    </div>
                                  } />
                              ))}
                            </div>

                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                            <Button
                              disabled={createresultt.isLoading ? true : false}
                              color="info"
                              variant="contained"
                              size="small"
                              type="submit">
                              {createresultt.isLoading ? 'Please wait while creating...' : 'Create'}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                    <MenuItem disabled ></MenuItem>
                  </Menu>
                </Dropdown>

                <Button
                  size='small'
                  color='warning'
                  style={{ textTransform: 'capitalize', margin: '0px 10px' }}
                >
                  <small>
                    Workers: {workerExist?.length}
                  </small>
                </Button>

                <Button
                  size='small'
                  color='warning'
                  style={{ textTransform: 'capitalize', margin: '0px 10px' }}
                >
                  <small>
                    In-progress: {workerinProgress?.length}
                  </small>
                </Button>

              </div>
              <div className="table_search_card">
                <div>
                  <Button
                    variant="outlined"
                    size="small"
                    component={Link}
                    to={`/store/${id}/import`}
                  >
                    Add Products CSV
                  </Button>
                  {/* <Button
                    // style={{background: '#4e5053'}}
                    style={{ border: '0.5px solid #4e5053', color: '#4e5053' }}
                    variant="outlined"
                    size="small"
                    onClick={() => imageUpdate()}>
                    Import CSV
                  </Button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="tabelOverflow" style={{ position: 'relative' }}>
            {getLoadingProgress(suppliersData?.isLoading, suppliersData?.isFetching) &&
              <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                <LinearProgress style={{ height: '2px' }} />
              </div>
            }
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: '0px' }}>ID</th>
                  <th style={{ textAlign: 'center' }}>Image</th>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Country</th>
                  <th className="sticky-column" style={{ textAlign: 'right' }}>Status</th>
                </tr>
              </thead>
              {/* {suppliersData?.isLoading &&
                TableSkeleton(8)
              } */}
              <tbody>
                {suppliers?.map((item, i) =>
                  <tr>
                    <td>{item.pid}</td>
                    <td style={{ textAlign: 'center' }}>

                      {item.images ?
                        <img src={`${item.images?.split(',')[0]}`} style={{ width: '35px', height: '35px', objectFit: 'contain' }} /> :
                        <img src={`${item.image}`} style={{ width: '35px', height: '35px', objectFit: 'contain' }} />
                      }
                    </td>
                    <td>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                          onClick={() => window.open(`https://www.desertcart.com/products/${item.pid}`, '_blank').focus()}
                        >
                          <LanguageIcon style={{ fontSize: '12px' }} />
                        </IconButton>
                        <Link to={`/product/${item._id}`} style={{ whiteSpace: 'initial' }}>
                          {item.title}
                        </Link>
                      </div>
                    </td>
                    <td>{item.currency} {item.price}</td>
                    <td>{item.country}</td>
                    <td className="sticky-column" style={{ textAlign: 'right' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                        <Tag style={{ margin: 0 }} color={item.status ? 'error' : 'success'}>{item.status ? 'Inactive' : 'Active'}</Tag>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {suppliers?.length === 0 &&
            <div style={{ padding: '20px', textAlign: 'center' }}>Not Data Found!</div>
          }
        </div>
      </div>
      <br /><br />
      {!suppliersData?.isLoading &&
        <>
          {suppliersData?.error?.originalStatus === 403 &&
            <div className="row reject-bottom-message" style={{ margin: 0, padding: 0, justifyContent: 'center' }}>
              <div className="col-md-6" style={{ padding: 0 }}>
                <div className='alert alert-warning' style={{ textAlign: 'center' }}>{suppliersData?.error?.data}</div>
              </div>
            </div>
          }
        </>
      }
    </div>
  );
}

export default Store;
