import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Modal } from 'react-bootstrap'
import './index.scss';
import "antd/dist/antd.min.css";
import { useFetchQuery, useDeleteMutation, useUpdateMutation } from '../../services/products';
import { useSelector } from "react-redux";
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { CKEditor } from 'ckeditor4-react';
import CloseIcon from '@mui/icons-material/Close';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import EditIcon from '@mui/icons-material/Edit';
import LanguageIcon from '@mui/icons-material/Language';
import TableSkeleton from '../TableSkeleton';
import StarPurple500OutlinedIcon from '@mui/icons-material/StarPurple500Outlined';
import LinearProgress from '@mui/material/LinearProgress';
import Loader from '../../Helper/Loader';
import { Tag } from 'antd';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';


function Products() {
  const { userId, userToken, userEmail, userName, admin } = useSelector((state) => state.auth);

  const location = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [getSearchQuery, searchQuery] = useState("")
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15
  const inSearch = new URLSearchParams(location.search).get('in_search') || ''
  const popular = new URLSearchParams(location.search).get('popular') || ''
  const status = new URLSearchParams(location.search).get('status') || 'all'

  const suppliersData = useFetchQuery({ status: status, page: page, size: limit, userId: userId, userToken: userToken, admin: admin, inSearch: inSearch, popular: popular }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const suppliers = suppliersData?.data && suppliersData?.data?.products;
  const pagination = suppliersData?.data && suppliersData?.data?.pagination;

  const [deleteList, deleteresult] = useDeleteMutation()
  const [show, setShow] = useState(false);
  const [getEditShow, setEditShow] = useState(false);


  const [getTitle, setTitle] = useState("")
  const [getCountry, setCountry] = useState("")
  const [getColor, setColor] = useState("")
  const [getWeight, setWeight] = useState(0)
  const [getQuantity, setQuantity] = useState("")
  const [getBrand, setBrand] = useState("")
  const [getCondition, setCondition] = useState("")
  const [getInStock, setInStock] = useState("")
  const [getTags, setTags] = useState("")
  const [getPrice, setPrice] = useState(0)
  const [getCategories, setCategories] = useState("")
  const [getFeatures, setFeatures] = useState("")
  const [getDetails, setDetails] = useState("")
  const [getImageUrls, setImageUrls] = useState("")
  const [getProductUrl, setProductUrl] = useState("")
  const [getUID, setUID] = useState("")
  const [getDeliveryDays, setDeliveryDays] = useState(0)
  const [getId, setId] = useState()


  function handleCloseEdit() {
    setEditShow(false)
  }
  function handleOpenEdit(data) {
    setEditShow(true)
    setTitle(data.title)
    setUID(data.asin)
    setCountry(data.country)
    setColor(data.color)
    setWeight(data.weight)
    setQuantity(data.quantity)
    setBrand(data.brand)
    setCondition(data.condition)
    setInStock(data.in_stock)
    setPrice(data.price)
    setCategories(data.category)
    setTags(data.tags)
    setFeatures(data.features)
    setDetails(data.details)
    setImageUrls(data.images)
    setProductUrl(data.product_url)
    setDeliveryDays(data.estimated_delivery)
    setId(data.id)

  }


  const [updatePost, updateresult] = useUpdateMutation()

  useEffect(() => {
    if (updateresult?.isSuccess) {
      setEditShow(false)
    }
  }, [updateresult]);


  const postFeatures = (evt) => {
    const data = evt.editor.getData();
    setFeatures(data);
  }

  const postDetails = (evt) => {
    const data = evt.editor.getData();
    setDetails(data);
  }


  const updateData = {
    title: getTitle,
    country: getCountry,
    color: getColor,
    weight: getWeight,
    quantity: getQuantity,
    brand: getBrand,
    condition: getCondition,
    in_stock: getInStock,
    tags: getTags,
    price: getPrice,
    category: getCategories,
    features: getFeatures,
    details: getDetails,
    images: getImageUrls,
    product_url: getProductUrl,
    estimated_delivery: getDeliveryDays,
    userId: userId,
    userToken: userToken,
    admin: admin,
    id: getId,
    update: 'update'
  }


  function productTitle(title) {
    return title?.replace(/[^A-Za-z0-9]/g, ' ')?.replace(/ /g, '-')
  }

  function functionIdLink(id, pid, store_id) {
    if (Number(store_id) === 3) {
      return pid
    } else {
      return id
    }
  }

  function getLoadingProgress(thisLoading, thisFetching) {
    if (thisLoading) {
      return true
    } else if (thisFetching) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className="tabel_cards">

      <div style={{ display: 'none' }}>
        <CKEditor
          style={{ display: 'none' }}
          data={``}
          config={{
            extraPlugins: "justify,showblocks",
            toolbar: [
              ['Source'],
              ['Styles', 'Format', 'Font', 'FontSize'],
              ['Bold', 'Italic'],
              ['Undo', 'Redo'],
              ['EasyImageUpload'],
              ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
              ['Maximize']
            ],
            height: '220px',
            allowedContent: false,
            direction: 'rtl',
            language: 'en',
            pasteFromWordRemoveFontStyles: false
          }}
          id="template_html"
        />
      </div>



      <Modal className="new-post-modal" show={getEditShow} onHide={handleCloseEdit} centered animation={true}>
        <Modal.Body>
          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={handleCloseEdit}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>Update Product</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Title</label>
                    <input type="text" className="form-control" defaultValue={getTitle} onChange={(e) => setTitle(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Country</label>
                    <select className="form-control" onChange={(e) => setCountry(e.target.value)}>
                      <option value={getCountry} selected>{getCountry}</option>
                      <option value={'AE'}>UAE</option>
                      <option value={'US'}>United State</option>
                      <option value={'UK'}>United Kingdom</option>

                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Price in USD</label>
                    <input type="number" className="form-control" defaultValue={getPrice} onChange={(e) => setPrice(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Weight in Grams</label>
                    <input type="number" className="form-control" defaultValue={getWeight} onChange={(e) => setWeight(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>UID</label>
                    <input type="text" disabled className="form-control" defaultValue={getUID} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Condition</label>
                    <input type="text" className="form-control" defaultValue={getCondition} onChange={(e) => setCondition(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Color</label>
                    <input type="text" className="form-control" defaultValue={getColor} onChange={(e) => setColor(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Brand</label>
                    <input type="text" className="form-control" defaultValue={getBrand} onChange={(e) => setBrand(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>In Stock</label>
                    <select className='form-control' onChange={(e) => setInStock(e.target.value)}>
                      <option selected value={getInStock}>{getInStock}</option>
                      <option value={'In Stock'}>In Stock</option>
                      <option value={'Out of Stock'}>Out of Stock</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Categories</label>
                    <input type="text" className="form-control" defaultValue={getCategories} onChange={(e) => setCategories(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Tags</label>
                    <input type="text" className="form-control" defaultValue={getTags} onChange={(e) => setTags(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Quantity</label>
                    <input type="number" className="form-control" defaultValue={getQuantity} onChange={(e) => setQuantity(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Delivery in Days</label>
                    <input type="number" className="form-control" defaultValue={getDeliveryDays} onChange={(e) => setDeliveryDays(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Image urls</label>
                    <input type="text" className="form-control" defaultValue={getImageUrls} onChange={(e) => setImageUrls(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Product URL</label>
                    <input type="text" className="form-control" defaultValue={getProductUrl} onChange={(e) => setProductUrl(e.target.value)} />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Features</label>
                    <CKEditor
                      data={``}
                      config={{
                        extraPlugins: "justify,showblocks",
                        toolbar: [
                          ['Source'],
                          ['Styles', 'Format', 'Font', 'FontSize'],
                          ['Bold', 'Italic'],
                          ['Undo', 'Redo'],
                          ['EasyImageUpload'],
                          ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
                          ['Maximize']
                        ],
                        height: '220px',
                        allowedContent: false,
                        direction: 'rtl',
                        language: 'en',
                        pasteFromWordRemoveFontStyles: false
                      }}
                      id="template_html"
                      onChange={postFeatures}
                      initData={getFeatures} />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label>Details</label>
                    <CKEditor
                      data={``}
                      config={{
                        extraPlugins: "justify,showblocks",
                        toolbar: [
                          ['Source'],
                          ['Styles', 'Format', 'Font', 'FontSize'],
                          ['Bold', 'Italic'],
                          ['Undo', 'Redo'],
                          ['EasyImageUpload'],
                          ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
                          ['Maximize']
                        ],
                        height: '220px',
                        allowedContent: false,
                        direction: 'rtl',
                        language: 'en',
                        pasteFromWordRemoveFontStyles: false
                      }}
                      id="template_html"
                      onChange={postDetails}
                      initData={getDetails} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Button disabled={updateresult?.isLoading ? true : false}
                      color="info"
                      variant="contained"
                      size="small"
                      onClick={() => { updatePost(updateData) }}
                    >
                      {updateresult?.isLoading ? 'Loading...' : 'Update'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="newFlexPagi">
          <div className="col-md-12">
            <div className="storeCardB">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span> Showing {pagination?.totalCounts && pagination?.totalCounts} entries &nbsp; &nbsp;</span>
                  {suppliersData?.isFetching && <Loader style={{ margin: '3px 0px' }} />}
                </div>
                <div className="Order-Pagination">
                  <Pagination
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px 0px 0px 0px' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    variant="outlined"
                    renderItem={(item) => (
                      <PaginationItem
                        component={Link}
                        to={{
                          pathname: `${window.location.pathname}`,
                          search: parseInt(item.page) === 1 ? status && `status=${status}` : `?page=${parseInt(item.page)}${inSearch && `&in_search=${inSearch}`}${popular && `&popular=${popular}`}${status && `&status=${status}`}`
                        }}
                        {...item}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="storeCardB">
            <div className="updateButton">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ position: 'relative', marginRight: '10px' }}>
                  <Dropdown>
                    <MenuButton
                      style={{ padding: '6px 8px', border: 'none', borderRadius: '6px', fontWeight: 500 }}
                      className="Header-button-connect">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ textTransform: 'capitalize' }}>Filter:&nbsp; {status}</span>
                        <ArrowDropDownIcon style={{ fontSize: '1.5rem', margin: '0 3px' }} />
                      </div>
                    </MenuButton>
                    <Menu>
                      <MenuItem disabled={status === 'all'} component={Link} to={`${window.location.pathname}?status=all`}>All Products</MenuItem>
                      <MenuItem disabled={status === 'active'} component={Link} to={`${window.location.pathname}?status=active`}>Active Products</MenuItem>
                      <MenuItem disabled={status === 'inactive'} component={Link} to={`${window.location.pathname}?status=inactive`}>Inactive Products</MenuItem>

                    </Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="table_search_card">

              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="tabelOverflow" style={{ position: 'relative' }}>
            {getLoadingProgress(suppliersData?.isLoading, suppliersData?.isFetching) &&
              <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                <LinearProgress style={{ height: '2px' }} />
              </div>
            }
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: '0px' }}>ID</th>
                  <th style={{ textAlign: 'center' }}>Image</th>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Country</th>
                  <th className="sticky-column" style={{ textAlign: 'right' }}>Status</th>
                </tr>
              </thead>
              <tbody style={{ position: 'relative' }}>

                {suppliers?.map((item, i) =>
                  <tr>
                    <td>{item.pid}</td>
                    <td style={{ textAlign: 'center' }}>
                      {item.images ?
                        <img src={`${item.images?.split(',')[0]}`} style={{ width: '35px', height: '35px', objectFit: 'contain' }} /> :
                        <img src={`${item.image}`} style={{ width: '35px', height: '35px', objectFit: 'contain' }} />
                      }
                    </td>
                    <td>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                          onClick={() => window.open(`https://www.desertcart.com/products/${item.pid}`, '_blank').focus()}
                        >
                          <LanguageIcon style={{ fontSize: '12px' }} />
                        </IconButton>
                        <Link to={`/product/${item._id}`} style={{ whiteSpace: 'initial' }}>
                          {item.title}
                        </Link>
                      </div>
                    </td>
                    <td>{item.currency} {item.price}</td>
                    <td>{item.country}</td>
                    <td className="sticky-column" style={{ textAlign: 'right' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                        <Tag style={{ margin: 0 }} color={item.status ? 'error' : 'success'}>{item.status ? 'Inactive' : 'Active'}</Tag>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {suppliers?.length === 0 &&
            <div style={{ padding: '20px', textAlign: 'center' }}>Not Data Found!</div>
          }

        </div>
      </div>
      <br /><br />

      {
        !suppliersData?.isLoading &&
        <>
          {suppliersData?.error?.originalStatus === 403 &&
            <div className="row reject-bottom-message" style={{ margin: 0, padding: 0, justifyContent: 'center' }}>
              <div className="col-md-6" style={{ padding: 0 }}>
                <div className='alert alert-warning' style={{ textAlign: 'center' }}>{suppliersData?.error?.data}</div>
              </div>
            </div>
          }
        </>
      }
    </div >
  );
}

export default Products;
