import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Spinner, Modal } from 'react-bootstrap'
import './index.scss';
import SearchIcon from "@material-ui/icons/Search";
import "antd/dist/antd.min.css";
import { useSelector } from 'react-redux'
import { useGetAllUsersQuery, useCreateUserMutation, useUpdateUserMutation } from '../../services/team';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { message, Tag, Switch as Clickable } from "antd";
import Moment from 'moment';
import Loader from '../../Helper/Loader';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import TableSkeleton from '../TableSkeleton';
import LinearProgress from '@mui/material/LinearProgress';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';



function Team() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, isAdmin, accessKey } = useSelector((state) => state.auth);
  const history = useHistory();
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const location = useLocation();
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15



  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [admin_access, setAdminAccess] = useState([]);
  const [admin_action, setAdminAction] = useState([]);

  // const handleAdminAccess = (event) => {
  //   const isChecked = event.target?.checked
  //   if (isChecked) {
  //     setAdminAccess([...admin_access, event.target.value])
  //   } else {
  //     const index = admin_access.indexOf(event.target.value)
  //     admin_access.splice(index, 1)
  //     setAdminAccess(admin_access)
  //   }
  // };

  const handleAdminAccess = (event) => {
    const isChecked = event.target.checked;
    const value = event.target.value;

    if (isChecked) {
      if (!admin_access) {
        setAdminAccess([value]);
      } else {
        setAdminAccess(prevState => [...prevState, value]);
      }
    } else {
      setAdminAccess(prevState => prevState.filter(item => item !== value));
    }
  };

  const handleAdminAction = (event) => {
    const isChecked = event.target.checked;
    const value = event.target.value;

    if (isChecked) {
      if (!admin_action) {
        setAdminAction([value]);
      } else {
        setAdminAction(prevState => [...prevState, value]);
      }
    } else {
      setAdminAction(prevState => prevState.filter(item => item !== value));
    }
  };

  const [getSearchId, setSearchId] = useState("")
  const [getNewModal, setNewModal] = useState(false)
  const [getOthersModal, setOthersModal] = useState(false)
  const [getName, setName] = useState('')
  const [getUsername, setUsername] = useState('')
  const [getReference, setReference] = useState('')

  const othersUserData = {
    name: getName,
    username: getUsername,
    reference: getReference
  }

  const usersInfo = useGetAllUsersQuery({
    page: page,
    size: limit,
    searchid: getSearchId,
    userId: userId,
    userToken: userToken,
    userRole: userRole
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const users = usersInfo?.data && usersInfo?.data?.users;
  const pagination = usersInfo?.data && usersInfo?.data?.pagination;


  const [createUser, createresult] = useCreateUserMutation()
  const [updateUser, updateresult] = useUpdateUserMutation()


  function createUserFunction(data) {
    createUser({
      email: data.email,
      password: data.password,
      reference: data.reference,
      user_role: 'admin',
      admin_access: { access: admin_access, action: admin_action },
      userToken: userToken,
      userId: userId,
      userRole: userRole
    })
  }


  function ebtidaEModal() {
    setNewModal(true)
    setAdminAccess([])
    setAdminAction([])
  }
  function closeNewModal() {
    setNewModal(false)
  }

  const [getUserUpdateModal, setUserUpdateModal] = useState(false)
  const [getUserUpdateData, setUserUpdateData] = useState([])
  const [getUserRole, setUserRole] = useState('')
  function updateUserModal(data) {
    setUserUpdateModal(true)
    setUserUpdateData(data)
    setAdminAccess(data?.admin_access?.access)
    setAdminAction(data?.admin_access?.action)
  }
  function closeUserUpdateModal() {
    setUserUpdateModal(false)
  }

  const bookItemData = {
    id: getUserUpdateData?._id,
    admin_access: { access: admin_access, action: admin_action },
    userId: userId,
    userToken: userToken,
    userRole: userRole
  }


  useEffect(() => {
    if (createresult?.isSuccess) {
      setNewModal(false)
      setOthersModal(false)
      reset()
    }
    if (updateresult?.isSuccess) {
      setUserUpdateModal(false)
    }
  }, [updateresult, createresult]);

  const customStyles = {
    control: styles => ({
      ...styles,
      width: 'auto'
    }),
    option: styles => ({
      ...styles,
      width: 'auto'
    }),
    menu: styles => ({
      ...styles,
      width: 'auto'
    })

  };

  function displayUserRole(role) {
    let name;
    if (role === 'admin') {
      name = 'Master'
    } else if (role === 'task') {
      name = 'Task'
    } else if (role === 'default') {
      name = ''
    } else {
      name = role
    }
    return name;

  }

  function getLoadingProgress(thisLoading, thisFetching) {
    if (thisLoading) {
      return true
    } else if (thisFetching) {
      return true
    } else {
      return false
    }
  }





  return (
    <div className="tabel_cards">
      <Modal className="team-page-modal" show={getUserUpdateModal} onHide={closeUserUpdateModal} animation={false}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>

          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={closeUserUpdateModal}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>Update Account</h5>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label style={{ fontSize: '1rem', fontWeight: 600 }}>Admin Access</label>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <FormControlLabel
                    disabled={admin_access?.length > 0 && !admin_access?.includes('full_access')}
                    onChange={handleAdminAccess}
                    control={<Checkbox
                      defaultChecked={getUserUpdateData?.admin_access?.access?.includes('full_access')}
                      name={'admin_access'}
                      value={'full_access'} />}
                    label="Full Access" />
                </div>

                <div className='t-a-access-section'>

                  <div className='t-a-access-card'>
                    <div className='t-a-access-item-title'>Google Merchants</div>
                    <div className='t-a-access-item'>
                      <FormControlLabel
                        style={{ margin: '0px 10px' }}
                        disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                        onChange={handleAdminAccess}
                        control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.access?.includes('google_merchants')} name={'admin_access'} value={'google_merchants'} />}
                        label="View" />
                      <FormControlLabel
                        style={{ margin: 0 }}
                        disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                        onChange={handleAdminAction}
                        control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.action?.includes('google_merchants_edit')} name={'admin_action'} value={'google_merchants_edit'} />}
                        label="Edit" />
                    </div>
                  </div>
                  <div className='t-a-access-card'>
                    <div className='t-a-access-item-title'>Stores</div>
                    <div className='t-a-access-item'>
                      <FormControlLabel
                        style={{ margin: '0px 10px' }}
                        disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                        onChange={handleAdminAccess}
                        control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.access?.includes('stores')} name='admin_access' value={'stores'} />}
                        label="View" />
                      <FormControlLabel
                        style={{ margin: 0 }}
                        disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                        onChange={handleAdminAction}
                        control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.action?.includes('stores_edit')} name={'admin_action'} value={'stores_edit'} />}
                        label="Edit" />
                    </div>
                  </div>
                  <div className='t-a-access-card'>
                    <div className='t-a-access-item-title'>Products</div>
                    <div className='t-a-access-item'>
                      <FormControlLabel
                        style={{ margin: '0px 10px' }}
                        disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                        onChange={handleAdminAccess}
                        control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.access?.includes('products')} name='admin_access' value={'products'} />}
                        label="View" />
                      <FormControlLabel
                        style={{ margin: 0 }}
                        disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                        onChange={handleAdminAction}
                        control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.action?.includes('products_edit')} name={'admin_action'} value={'products_edit'} />}
                        label="Edit" />
                    </div>
                  </div>

                  <div className='t-a-access-card'>
                    <div className='t-a-access-item-title'>Countries Route</div>
                    <div className='t-a-access-item'>
                      <FormControlLabel
                        style={{ margin: '0px 10px' }}
                        disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                        onChange={handleAdminAccess}
                        control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.access?.includes('countries')} name='admin_access' value={'countries'} />}
                        label="View" />
                      <FormControlLabel
                        style={{ margin: 0 }}
                        disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                        onChange={handleAdminAction}
                        control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.action?.includes('countries_edit')} name={'admin_action'} value={'countries_edit'} />}
                        label="Edit" />
                    </div>
                  </div>
                  <div className='t-a-access-card'>
                    <div className='t-a-access-item-title'>Team</div>
                    <div className='t-a-access-item'>
                      <FormControlLabel
                        style={{ margin: '0px 10px' }}
                        disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                        onChange={handleAdminAccess}
                        control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.access?.includes('team')} name='admin_access' value={'team'} />}
                        label="View" />
                      <FormControlLabel
                        style={{ margin: 0 }}
                        disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                        onChange={handleAdminAction}
                        control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.action?.includes('team_edit')} name={'admin_action'} value={'team_edit'} />}
                        label="Edit" />
                    </div>
                  </div>


                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                <Button
                  color="info"
                  variant="contained"
                  size="small"
                  onClick={() => !updateresult.isLoading && updateUser(bookItemData)}>
                  {updateresult.isLoading ? <Loader /> : 'Update'}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>




      <Modal className="team-page-modal" show={getNewModal} onHide={closeNewModal} animation={false}>
        <Modal.Body>
          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={closeNewModal}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>Create New Account</h5>
            </div>
          </div>
          <form onSubmit={handleSubmit(createUserFunction)}>
            {createresult?.error?.data?.error &&
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1rem', fontWeight: 400, textAlign: 'center' }}>
                <span className="col-md-12 alert alert-danger">{createresult?.error?.data?.error}</span>
              </div>
            }
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label style={{ fontSize: '1rem', fontWeight: 600 }}>Reference</label>
                  <input type="text" className="form-control"
                    id="reference"
                    aria-invalid={errors.reference ? "true" : "false"}
                    {...register('reference', { required: true })}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label style={{ fontSize: '1rem', fontWeight: 600 }}>Admin Access</label>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <FormControlLabel
                      disabled={admin_access?.length > 0 && (!admin_access?.includes('full_access'))}
                      onChange={handleAdminAccess}
                      control={<Checkbox name={'admin_access'} value={'full_access'} />}
                      label="Full Access" />
                  </div>
                  <div className='t-a-access-section'>

                    <div className='t-a-access-card'>
                      <div className='t-a-access-item-title'>Google Merchants</div>
                      <div className='t-a-access-item'>
                        <FormControlLabel
                          style={{ margin: '0px 10px' }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAccess}
                          control={<Checkbox name={'admin_access'} value={'google_merchants'} />}
                          label="View" />
                        <FormControlLabel
                          style={{ margin: 0 }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAction}
                          control={<Checkbox name={'admin_access'} value={'google_merchants_edit'} />}
                          label="Edit" />
                      </div>
                    </div>
                    <div className='t-a-access-card'>
                      <div className='t-a-access-item-title'>Stores</div>
                      <div className='t-a-access-item'>
                        <FormControlLabel
                          style={{ margin: '0px 10px' }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAccess}
                          control={<Checkbox name='admin_access' value={'stores'} />}
                          label="View" />
                        <FormControlLabel
                          style={{ margin: 0 }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAction}
                          control={<Checkbox name={'admin_access'} value={'stores_edit'} />}
                          label="Edit" />
                      </div>
                    </div>
                    <div className='t-a-access-card'>
                      <div className='t-a-access-item-title'>Products</div>
                      <div className='t-a-access-item'>
                        <FormControlLabel
                          style={{ margin: '0px 10px' }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAccess}
                          control={<Checkbox name='admin_access' value={'products'} />}
                          label="View" />
                        <FormControlLabel
                          style={{ margin: 0 }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAction}
                          control={<Checkbox name={'admin_access'} value={'products_edit'} />}
                          label="Edit" />
                      </div>
                    </div>

                    <div className='t-a-access-card'>
                      <div className='t-a-access-item-title'>Countries</div>
                      <div className='t-a-access-item'>
                        <FormControlLabel
                          style={{ margin: '0px 10px' }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAccess}
                          control={<Checkbox name='admin_access' value={'countries'} />}
                          label="View" />
                        <FormControlLabel
                          style={{ margin: 0 }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAction}
                          control={<Checkbox name={'admin_access'} value={'countries_edit'} />}
                          label="Edit" />
                      </div>
                    </div>
                    <div className='t-a-access-card'>
                      <div className='t-a-access-item-title'>Team</div>
                      <div className='t-a-access-item'>
                        <FormControlLabel
                          style={{ margin: '0px 10px' }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAccess}
                          control={<Checkbox name='admin_access' value={'team'} />}
                          label="View" />
                        <FormControlLabel
                          style={{ margin: 0 }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAction}
                          control={<Checkbox name={'admin_access'} value={'team_edit'} />}
                          label="Edit" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label style={{ fontSize: '1rem', fontWeight: 600 }}>Email</label>
                  <input type="email" className="form-control"
                    id="email"
                    aria-invalid={errors.email ? "true" : "false"}
                    {...register('email', { required: true })} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label style={{ fontSize: '1rem', fontWeight: 600 }}>Password</label>
                  <input type="password" autoComplete="off" className="form-control"
                    id="password"
                    aria-invalid={errors.password ? "true" : "false"}
                    {...register('password', { required: true, minLength: 8, maxLength: 16 })} />
                  {errors.password && errors.password.type === "required" && <span style={{ color: "red", fontSize: "12px" }}>required*</span>}
                  {errors.password && errors.password.type === "maxLength" && <span style={{ color: "red", fontSize: "12px" }}>Password should be maximum 16 digits</span>}
                  {errors.password && errors.password.type === "minLength" && <span style={{ color: "red", fontSize: "12px" }}>Password should be minimum 8 digits</span>}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                  <Button
                    disabled={createresult.isLoading ? true : false}
                    color="info"
                    variant="contained"
                    size="small"
                    type="submit">
                    {createresult.isLoading ? 'Loading...' : 'Create'}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>




      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="newFlexPagi">
          <div className="col-md-12">
            <div className="storeCardB">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap' }}>
                <div>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>
                <div className="Order-Pagination">

                  <Pagination
                    className='list-paginatiion-table'
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    disabled={usersInfo?.isLoading ? true : false}
                    renderItem={(item) => (
                      <PaginationItem
                        component={Link}
                        to={{
                          pathname: `${window.location.pathname}`,
                          search: `?page=${parseInt(item.page)}`
                        }}
                        {...item}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="storeCardB">
            <div className="updateButton">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ position: 'relative', marginRight: '10px' }}>
                  <input style={{ borderRadius: '100px' }} className="form-control table_search" placeholder="Search..." onChange={(e) => setSearchId(e.target.value)} />
                  {/* {usersInfo?.isFetching &&
                    <Spinner style={{ width: '1rem', height: '1rem', fontSize: '7px', position: 'absolute', top: '12px', right: '40px', color: '#cbcbcb' }} animation="border" />
                  } */}
                  <SearchIcon className="table_search_icon" />
                </div>
              </div>
              <div className="table_search_card">
                <div>
                  <Button
                    // style={{background: '#4e5053'}}
                    style={{ border: '0.5px solid #4e5053', color: '#4e5053' }}
                    variant="outlined"
                    size="small"
                    onClick={() => ebtidaEModal()}>
                    Add New
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>

          <div className="tabelOverflow" style={{ position: 'relative' }}>
            {getLoadingProgress(usersInfo?.isLoading, usersInfo?.isFetching) &&
              <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                <LinearProgress style={{ height: '2px' }} />
              </div>
            }
            <table className="table">
              <thead>
                <tr>
                  <th style={{ textAlign: 'center' }}></th>
                  <th>#</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Reference</th>
                  <th>Updated At</th>
                  <th className="sticky-column" style={{ textAlign: 'right' }}>
                    Manage
                  </th>

                </tr>
              </thead>
              {users?.length === 0 ?
                <h5 className="data_not-found">No data available in table</h5>
                : null}
              <tbody>
                {/* <>
                  {usersInfo?.isLoading &&
                    TableSkeleton(8)
                  }
                </> */}
                {users?.map((item, i) =>
                  <tr key={i}>
                    <td>
                      {item.email !== 'admin@admin.com' &&
                        <div>

                          <a onClick={e => e.preventDefault()}>
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? 'long-menu' : undefined}
                              aria-expanded={open ? 'true' : undefined}
                              aria-haspopup="true"
                              onClick={() => updateUserModal(item)}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </a>
                        </div>
                      }
                    </td>
                    <td>
                      <div>{item.id}</div>
                    </td>
                    <td>
                      <div>
                        <div> {item.name && item.name + ', '}{item.email ? item.email : item.username}</div>
                      </div>
                    </td>
                    <td>
                      <div>{item.phone}</div>
                    </td>
                    <td>
                      {item.reference &&
                        <Tag className="booksTag" color={"#55acee"}>
                          <small>{item.reference}</small>
                        </Tag>
                      }
                    </td>
                    <td>
                      <div>{Moment(item.createdAt).format('DD-MM-YYYY')}</div>
                    </td>
                    <td className="sticky-column" style={{ textAlign: 'right' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                        {item.state &&
                          <Tag className="booksTag" color={"#7dc487"}>
                            <small>{item.state}</small>
                          </Tag>
                        }
                        <div>
                          <label>Active</label>
                          <Clickable
                            className='team-switch-button'
                            style={{ margin: '0px 5px' }}
                            defaultChecked={item.status ? true : false}
                            onChange={() => item.email !== 'admin@admin.com' && updateUser({
                              id: item._id,
                              status: item.status ? false : true,
                              status_update: 'status_update',
                              userId: userId,
                              userToken: userToken,
                              userRole: userRole
                            })}
                          />
                        </div>
                        <div>
                          <label>Admin</label>
                          <Clickable
                            className='team-switch-button'
                            style={{ margin: '0px 5px' }}
                            defaultChecked={item.admin ? true : false}
                            onChange={() => item.email !== 'admin@admin.com' && updateUser({
                              id: item._id,
                              admin: item.admin ? false : false,
                              admin_update: 'admin_update',
                              userId: userId,
                              userToken: userToken,
                              userRole: userRole
                            })}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                )
                }
              </tbody>
            </table>
          </div>

        </div>
      </div>
      {!usersInfo?.isLoading &&
        <>
          {usersInfo?.error?.originalStatus === 403 &&
            <div className="row reject-bottom-message" style={{ margin: 0, padding: 0, justifyContent: 'center' }}>
              <div className="col-md-6" style={{ padding: 0 }}>
                <div className='alert alert-warning' style={{ textAlign: 'center' }}>{usersInfo?.error?.data}</div>
              </div>
            </div>
          }
        </>
      }
    </div>
  );
}

export default Team;
